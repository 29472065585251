import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Section,
  Box,
  Text,
} from "../../components/Core";

import { device } from "../../utils";

// Taken from https://unsplash.com/photos/9SoCnyQmkzI
import heroImage from "../../assets/images/hero-unsplash.jpg";

const SectionStyled = styled(Section)`
  background:
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ), url(${heroImage}) no-repeat;
  background-size: cover;
  background-position: left;
  @media ${device.lg} {
    background-position: center;
  };

  .subtitle {
    line-height: 1.5;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <div className="pt-5"></div>
        <Container>
          <Row>
            <Col
              sm="12"
              md="12"
              lg="9"
              className="order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                <Title variant="hero" color="white">
                  We love building performant, maintainable web applications
                </Title>
                <Text color="white" mb={4} className="subtitle">
                  Our mission is developing thoughtfully-architected web apps that continue to be reliable and extensible long after we’re done building them
                </Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
