import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/index/Hero";
import Skills from "../sections/index/Skills";
import Work from "../sections/index/Work";
import Contact from "../sections/index/Contact";

const IndexPage = () => {
  return (
    <>
      <PageWrapper headerLight footerDark>
        <Hero />
        <Skills />
        <Work />
        <Contact />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
