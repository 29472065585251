import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SkillCard = ({
  color = "primary",
  title,
  children,
  ...rest
}) => (
  <Box width={"100%"} bg="light" p="30px" borderRadius={10} {...rest}>
    <div className="d-flex justify-content-between align-items-start">
      <div>
        <Text
          color="heading"
          as="h3"
          fontSize={4}
          fontWeight={500}
          letterSpacing={-0.75}
          mb={2}
        >
          {title}
        </Text>
        <Text fontSize={2} lineHeight={1.75}>
          {children}
        </Text>
      </div>
    </div>
  </Box>
);

const Skills = () => (
  <>
    <Section bg="ash">
      <Container>
        <Row className="align-items-center">
          <Col
            md="12"
            lg="6"
            className="offset-lg-1 pl-lg-5 order-lg-2"
            data-aos="fade-left"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <Box>
              <Title color="light">These are some of the things we're great at</Title>
              <Text color="light" opacity={0.7}>
                We love working with clients to help them brainstorm, plan, and execute the development of their web
                application.
              </Text>
              <br></br>
              <Text color="light" opacity={0.7}>
                We've got experience building all types of web apps, whether they be content management systems,
                e-commerce platforms, APIs to support mobile applications including those for Android and iOS, and more.
              </Text>
              <br></br>
              <Text color="light" opacity={0.7}>
                Web apps are machines made of logic, and we take the necessary time and care to build them in a way that
                ensures their future extensibility and maintainability.
              </Text>
            </Box>
          </Col>
          <Col md="12" lg="5" className="order-lg-1 mt-5 mt-lg-0">
            <div>
              <Row className="justify-content-center">
                <Col
                  md="12"
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <SkillCard
                    title="Building your next big idea"
                  >
                    Whether it be a content management system, an e-commerce platform, or an API to support mobile apps,
                    we've got the skills and experience to make it a success.
                  </SkillCard>
                </Col>
                <Col
                  md="12"
                  className="mt-4"
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <SkillCard
                    title="Giving your existing project some well-considered TLC"
                  >
                    We love squashing troublesome bugs, tackling major dependency version upgrades, and just generally
                    giving modern web applications the ongoing attention and care they need to run reliably and securely.

                    <br></br>
                    <br></br>

                    Basically, leaving your app in a better state than we found it in is what we're all about.
                  </SkillCard>
                </Col>
                <Col
                  md="12"
                  className="mt-4"
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <SkillCard
                    title="Technical advisory and direction"
                  >
                    Have your own development team but need additional expertise to help guide your project in the
                    right direction? We can do that too.
                  </SkillCard>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Skills;
