import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

const StyledSection = styled(Section)`
  a:active, a:focus, a:visited {
    color: unset !important;
  }
  a:hover {
    color: ${({ theme }) => theme.colors.dark} !important;
    text-decoration: underline !important;
  }
`

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.gray};
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const Contact = () => {
  return (
    <>
      <StyledSection>
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg="8">
              <div className="banner-content">
                <Title>Say hello 👋</Title>
                <Text>
                  Get in touch — we'd love to hear from you!
                </Text>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-5 pt-lg-5">
            <Col xl="12">
              <WidgetWrapper>
                <Row>
                  <Col sm="12" md="6">
                    <Box className="mb-5">
                      <Title variant="card" fontSize="24px">
                        Email us
                      </Title>
                      <Text><a href="mailto:hello@wolfflabs.com">hello@wolfflabs.com</a></Text>
                    </Box>
                  </Col>
                  <Col sm="12" md="6">
                    <Box className="mb-5">
                      <Title variant="card" fontSize="24px">
                        Write to us
                      </Title>
                      <Text>Wolff Labs Pte. Ltd.</Text>
                      <Text>7 Temasek Boulevard, #12-07</Text>
                      <Text>Suntec Tower One</Text>
                      <Text>Singapore 038987</Text>
                    </Box>
                  </Col>
                </Row>
              </WidgetWrapper>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    </>
  );
};

export default Contact;
