import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box } from "../../components/Core";
import PostCardNoLinks from "../../components/PostCardNoLinks";

import imgEbriefReadyWebsite from "../../assets/images/ebriefready-website-1400.jpg";
import imgTriplerWebsite from "../../assets/images/rrr-website-1400.jpg";
import imgSuppApp from "../../assets/images/supp-app.jpg";

const Work = () => (
  <>
    <Section bg="gray">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12">
            <Box className="text-center" mb={[4, 5]}>
              <Title>
                What we've been working on lately
              </Title>
            </Box>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCardNoLinks
              img={imgEbriefReadyWebsite}
              title="eBrief Ready"
            >
              Spearheading the development of the next-generation electronic briefing platform that's helping
              to bring Australian courtrooms into the digital age (and saving thousands of trees in the process)
            </PostCardNoLinks>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCardNoLinks
              img={imgSuppApp}
              title="Supp"
            >
              Building and extending APIs that power the mobile apps for the Australian hospitality marketplace that
              enables venues and industry professionals to find each other for short (or even longer-term) gigs
            </PostCardNoLinks>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCardNoLinks
              img={imgTriplerWebsite}
              title="Triple R"
            >
              Ongoing web platform development and proactive maintenance for Triple R, Melbourne's (and maybe even
              Australia's) most-loved and most-influential community radio station
            </PostCardNoLinks>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Work;
